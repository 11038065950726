import * as React from "react"
import { useContext } from "react"
import { Link } from "gatsby"
import Image from "gatsby-image"
import { useStaticQuery, graphql } from "gatsby"
import { useTheme } from "emotion-theming"
import { ThemeManagerContext } from "gatsby-emotion-dark-mode"

import { dimensions, styled, Theme } from "src/styles/theme"

const isHeroNavBarHeightRem = 6
const navBarHeightRem = 4
const logoSizeRem = 3

const NavContainer = styled.div`
  width: ${dimensions.mobileWidth};
  max-width: ${dimensions.contentMaxWidth};
`

const Nav = styled.nav`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: ${(props) => (props.isHero ? isHeroNavBarHeightRem : navBarHeightRem)}rem;
`

const NavTitle = styled.h5`
  color: ${(props) => props.theme.navTitle};
  line-height: ${logoSizeRem}rem;
  padding: 0;
  margin: 0;
  font-size: ${0.5 * logoSizeRem}rem;
`

const Logo = styled(Link)`
  height: ${logoSizeRem}rem;
  width: ${logoSizeRem}rem;
  border-radius: ${0.5 * logoSizeRem}rem;
  background-color: ${(props) => props.theme.homeLogoBackground};
  text-align: center;
  &:hover,
  &:focus {
    text-decoration: none;
  }
`

const NavIcon = styled(Image)`
  height: ${logoSizeRem}rem;
  width: ${logoSizeRem}rem;
  border-radius: ${0.5 * logoSizeRem}rem;
`

const RightNav = styled.div`
  display: flex;
  flex-direction: row;
  align-items: right;
`

const ToggleThemeButton = styled.h6`
  line-height: ${navBarHeightRem}rem;
  margin: 0;
  padding: 0;
  padding-left: 5vw;
  cursor: pointer;
`

const NavBar: React.FC<{ isHero?: boolean }> = ({ isHero = false }) => {
  const { toggleDark } = useContext(ThemeManagerContext)
  const theme = useTheme<Theme>()

  const data = useStaticQuery<GatsbyTypes.NavBarQuery>(graphql`
    query NavBar {
      allFile(filter: { name: { eq: "icon" }, extension: { eq: "jpg" } }) {
        nodes {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  `)

  return (
    <NavContainer>
      <Nav isHero={isHero}>
        {isHero ? (
          <Logo to="/">
            <NavIcon fluid={data?.allFile?.nodes[0]?.childImageSharp?.fluid} />
          </Logo>
        ) : (
          <Link to="/">
            <NavTitle>⭠</NavTitle>
          </Link>
        )}

        <RightNav>
          <ToggleThemeButton onClick={() => toggleDark()}>
            {theme.symbol}
          </ToggleThemeButton>
        </RightNav>
      </Nav>
    </NavContainer>
  )
}

export default NavBar
