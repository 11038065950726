import * as React from "react"

import { styled, dimensions } from "src/styles/theme"

const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 0;
  align-items: center;
`

const ArticleContainer = styled.section`
  width: ${dimensions.mobileWidth};
  max-width: ${dimensions.contentMaxWidth};
`

const Section: React.FC = ({ children }) => (
  <StyledContainer>
    <ArticleContainer>{children}</ArticleContainer>
  </StyledContainer>
)

export default Section
